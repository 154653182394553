import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";

var mode = (process.env.REACT_APP_WHATSAPP_MODE);
var execution = (mode === "PRODUCTION") ? 1 : 2;
var firebaseConfig = {};

var firebaseConfig1 = {
    apiKey: "AIzaSyCi2czDgEmp-ZnSUMCQ4CNmXrbto5EI7jg",
    authDomain: "crm-whatsapp-8ccd5.firebaseapp.com",
    databaseURL: "https://crm-whatsapp-8ccd5-default-rtdb.firebaseio.com",
    projectId: "crm-whatsapp-8ccd5",
    storageBucket: "crm-whatsapp-8ccd5.appspot.com",
    messagingSenderId: "448731763457",
    appId: "1:448731763457:web:8b6be7d625dbe8e992c100"
};

var firebaseConfig2 = {
    apiKey: "AIzaSyDqm7YJrqjocFaeSYwzx5Umq45CJGSaseg",
    authDomain: "sempnet-whatsapp.firebaseapp.com",
    databaseURL: "https://sempnet-whatsapp-default-rtdb.firebaseio.com",
    projectId: "sempnet-whatsapp",
    storageBucket: "sempnet-whatsapp.appspot.com",
    messagingSenderId: "328745329962",
    appId: "1:328745329962:web:40ea0d93e392c46e302a53"
};

firebaseConfig = (execution === 1) ? firebaseConfig1 : firebaseConfig2;

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

export {database, ref, onValue, getAuth, signInWithCustomToken};