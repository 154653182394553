import React, { Component } from "react";
import "./WhatsAppInProgress.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import WhatsApp from "./WhatsApp";

const initialState = {

}

class WhatsAppInProgress extends Component
{
    state = {...initialState}
    
    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - WhatsApp (em atendimento)`;
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("WHATSAPP IN PROGRESS prevProps ", prevProps);
        // console.log("WHATSAPP IN PROGRESS this.props ", this.props);

        if (!this.props.whatsapp.toView) 
        { this.props.history.push(`/activities`); }
    }

    render ()
    {
        return (
            <div className="whatsAppInProgress">
                <WhatsApp />
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {user} = state.auth;
    const permissions = state.permissions;
    const {hasPermissionsLoaded, whatsapp} = permissions;

    return {
        user,
        whatsapp,
        permissions,
        hasPermissionsLoaded,
    }
}

function mapDispatchToProps (dispatch)
{
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WhatsAppInProgress));